/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // $h = $('.row').parent('section').outerHeight();
        // $('.row').height($h);

        fluidvids.init({
          selector: ['iframe', 'object'], // runs querySelectorAll()
          players: ['www.youtube.com', 'player.vimeo.com'] // players to support
        });


      },
      finalize: function() {

      // Media Query example usage
        var mediaQuery = window.matchMedia('(min-width: 760px)'); //tablet defined in css
        // Function to do something with the media query
        function doSomething(mediaQuery) {
          if (mediaQuery.matches) {

          } else {}
        }

        // Add a listen event
        mediaQuery.addListener(doSomething);
        // On load
        doSomething(mediaQuery);
        // end Media Query examplel usage

        // JavaScript to be fired on all pages, after page specific JS is fired
        // Hamburger Menu Button Actions
        var slideMenu = (function() {
          $('#hamburger').on('click', function() {
            $('body').toggleClass('menu-active');
          });
          $('.main, .sidebar').on('click', function() {
            if ($('body').hasClass('menu-active')) {
              $('body').toggleClass('menu-active');
            }
          });
        })();



        var landingSlides = (function() {

          $('#landing .full_slides').slick({
            dots: true,
            autoplay: true,
            arrows: false,
            fade: true,
            autoplaySpeed: 4000,
            speed: 2500
          });

        })();

        var headshotSlides = (function() {

          // $('#headshots .full_slides').slick({
          //   dots: true,
          //   autoplay: false,
          //   arrows: false,
          //   fade: false,
          //   autoplaySpeed: 4000,
          //   speed: 500,
          //   // slidesToShow: 3,
          //   // slidesToScroll: 3,
          //   centerMode: false,
          //   centerPadding: 250,
          //   adaptiveHeight: false,
          //   variableWidth: false,
          //   rows: 3,
          //   slidesPerRow: 3

          // });

        })(); 

        var reviews = (function() {

          $('#quote .full_slides').slick({
            dots: false,
            autoplay: true,
            arrows: false,
            fade: false,
            autoplaySpeed: 4000,
            speed: 500,
            // slidesToShow: 3,
            // slidesToScroll: 3,
            centerMode: false,
            centerPadding: 250,
            adaptiveHeight: false,
            variableWidth: false,
            rows: 1,
            slidesPerRow: 1

          });

        })();


        // ======================================
        // Helper functions
        // ======================================
        // Get section or article by href
        function getRelatedContent(el) {
          return $($(el).attr('href'));
        }
        // Get link by section or article id
        function getRelatedNavigation(el) {
          return $('nav a[href=#' + $(el).attr('id') + ']');
        }


        // ======================================
        // Smooth scroll to content
        // ======================================
        var scrollTo =(function(){

          $('nav a').on('click', function(e) {
            e.preventDefault();
            $('html,body').animate({
              scrollTop: getRelatedContent(this).offset().top
            });
          });

          $('a[href=#]').on('click', function(e){
            e.preventDefault();
            $('html,body').animate({
              scrollTop:0
            }, 600);
            
          });
          
        })();

        // ======================================
        // Nav Highlight to content scroll
        // ======================================
        // var navHighlight = (function(){



        var sections = $('section'),
          nav = $('nav'),
          nav_height = nav.outerHeight();

        function NavHighlight() {
          var cur_pos = $(this).scrollTop();

          sections.each(function() {
            var top = $(this).offset().top - nav_height,
              bottom = top + $(this).outerHeight();

            if (cur_pos >= top && cur_pos <= bottom) {
              nav.find('a').parent().removeClass('active');
              sections.removeClass('active');

              $(this).addClass('active');
              nav.find('a[href="#' + $(this).attr('id') + '"]').parent().addClass('active');
            }
          });
        }
        var navLinkHighlight = _.debounce(NavHighlight, 200);
        $(window).on('scroll', navLinkHighlight);



        // });





        function fixedSizing() {
          $w = $('.sidebar').outerWidth();
          $('.fixed-wrap').outerWidth($w);
        }
        fixedSizing();

        var fixedWidth = _.debounce(fixedSizing, 250);
        $(window).on('resize', fixedWidth);
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }

  }; // do not remove this bracket

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
